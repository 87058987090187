<template>
  <div class="box">
    <div v-show="!isNO">
      <!-- <div class="top" :class="{ 'timeout': table.timeout }">
        <img src="@/assets/logo.jpg" alt="">
        <div>石家庄市食品从业人员健康检查</div>
      </div> -->
      <div style="display: none;">
        <!-- <img id="qrImg" src="" alt=""> -->
        <div id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div v-if="isNO"  class="center red">
        您的健康证已过期，请尽快重新办理
      </div>
      <div v-else class="center">
        您的健康证真实有效
      </div>

      <div class="bottom">
        <div class="boxT">
          石家庄市食品药品从业人员健康证明
        </div>
        <!-- <img style="position: absolute;width: 100%;top: 0;" src="@/assets/bg3.jpg" alt=""> -->
        <!-- <div>姓名: {{ isNO ? '': table.name }}</div>
        <div>性别: {{ table.sex }}</div>
        <div>体检单位: {{ isNO ? '--': table.unit }}</div>
        <div>体检日期: {{ isNO ? '--': table.dataTime }}</div>
        <div>是否合格: {{ isNO ? '--': table.timeout ? '不合格':'合格'}}</div> -->
        <div style="display: flex;">
          <div class="leftBox" style="z-index: 999;">
            <div style="position: relative;">
              <img v-show="!isNO" style="width: 66%;" :src="table.picture" alt="">
              <img v-show="!isNO" class="hz" :src="table.hzUrl" alt="">
              <div style="position: absolute;top: 2px;left: 3px;font-size: 10px;font-weight: 600;">{{ isNO ? '--': table.pictureNumber }}</div>
            </div>
            <div class="leftDiv" style="margin-top: 8px;">类&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型：{{ isNO ? '--': table.category }}类</div>
            <div class="leftDiv">编&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：{{ isNO ? '--': table.number }}</div>
            <div class="leftDiv">体检日期：{{ isNO ? '--': table.dataTime }}</div>
          </div>
          <div class="rightBox" style="z-index: 999;">
            <div style="display: flex;">
              <img class="ewm" :src="url" alt="">
              <div style="margin-left: 10px;">
                <div class="leftDiv" style="margin-top: 2.5px;">姓&emsp;&nbsp;&nbsp;&nbsp;名：{{ isNO ? '': table.name }} ({{ isNO ? '': table.sex  }})</div>
                <div class="leftDiv">证件号码：{{ isNO ? '': table.idNumber }}</div>
                <div class="leftDiv">举报电话：{{ isNO ? '--':'12315'}}</div>
              </div>
            </div>
            <div style="margin-top: 7px;font-size: 8px;" v-show="!isNO">
              <table style="border-collapse: collapse; width: 94%;">
                <tr>
                  <th style="border: 1px solid #000;width: 67%;">检查项目</th>
                  <th style="border: 1px solid #000;width: 33%;">检查结果</th>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">霍乱</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">细菌性和阿米巴性痢疾</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">伤寒和副伤寒</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">病毒性肝炎(甲型、戊型)</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">活动性肺结核</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #000;padding-left: 15px;">化脓性或者渗出性皮肤病</td>
                  <td style="border: 1px solid #000;text-align: center;">合格</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <!-- <div class="boxB"></div>   -->

      </div>
      <div class="boxB"></div> 
    </div>

  </div>
</template>

<script>
import { getData2 } from '@/api/index2.js'
import QRCode from 'qrcodejs2'
export default {
  components: {},
  props: {},
  data() {
    return {
      id: '',
      url: '',
      isNO: true,
      table: {
        category: '食品'
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.id = this.$route.query.id
    getData2(this.$route.query.id).then(res => {
      this.table = res.data
      console.log('sub', res.data)
      if (!this.table || this.table == null) {
        this.isNO = true
        return
      } else {
        this.isNO = false
      }
      if (this.table.timeout || this.table.status == 0 || this.table.switchStatus == 0) {
        this.isNO = true
      } else {
        this.table.picture = 'https://hbldqy.cn/back'+this.table.picture
        this.table.hzUrl = 'https://hbldqy.cn/back/images/' + this.table.imgType + '.png'
        this.isNO = false
        this.setImg()
      }
    })
  },
  methods: {
    setImg() {
      new QRCode(this.$refs.qrCodeUrl, {
        // text: `http://192.168.1.57:8089/index/index/user?id=${id}`, // 需要转换为二维码的内容
        // text: `http://47.116.28.55/index/index/user?id=${id}`, // 需要转换为二维码的内容
        text: `https://hbldqy.cn/index/qrcode?id=${this.id}`, // 需要转换为二维码的内容
        width: 297,
        height: 297,
        colorDark: '#000000',
        colorLight: '#ffffff00',
        correctLevel: QRCode.CorrectLevel.L
      })
      // console.log(`http://192.168.1.57:8089/index/index/user?id=${id}`)
      const qrcode = document.querySelector('#qrcode')
      const canvas = qrcode.querySelector('canvas')
      const imgurl = canvas.toDataURL('image/png')
      // console.log('imgurl', imgurl)
      // const a = document.createElement('a')
      // a.href = imgurl
      // a.download = '二维码' // 图片名称
      // a.click()
      // var base64 = imgurl; // imgSrc 就是base64哈
      this.url = imgurl
      // var byteCharacters = atob(
      //   base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
      // );
      // var byteNumbers = new Array(byteCharacters.length);
      // for (var i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // var byteArray = new Uint8Array(byteNumbers);
      // var blob = new Blob([byteArray], {
      //   type: undefined,
      // });
      // var aLink = document.createElement("a");
      // aLink.download = `${name}.png`; //这里写保存时的图片名称
      // aLink.href = URL.createObjectURL(blob);
      // aLink.click();
      // qrcode.innerHTML=''
    },
  }
}
</script>
<style lang="scss" scoped>
.box {
  min-height: 100vh;
  width: 100%;
  word-break:keep-all;      /* 不换行 */
  white-space:nowrap;       /* 不换行 */
}
.top {
  display: flex;
  justify-content: center;
  // justify-items: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 70px auto 40px;
  font-size: 18px;
  font-weight: 700;
  color: rgb(61, 61, 61);
  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
.timeout {
  justify-content: center;
}
.top2 {
  width: fit-content;
  margin: 85px auto 40px;
  font-size: 18px;
  font-weight: 700;
  color: rgb(61, 61, 61);
}
.center {
  width: fit-content;
  color: rgb(0, 181, 120);
  height: 24px;
  font-size: 19px;
  font-weight: 700;
  margin: 35px auto 20px;
}
.boxT {
  width: 100%;
  // margin: 0 auto;
  background-color: #4d6dfe;
  text-align: center;
  height: 43px;
  line-height: 43px;
  margin-bottom: 9px;
  color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 14px;
  font-weight: 600;
}
.boxB {
  width: 95%;
  margin: 0 auto;
  // margin-top: 10px;
  background-color: #4d6dfe;
  text-align: center;
  height: 10px;
  color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 15px;
}
.bottom {
  // padding: 20px 0; 
  padding-bottom: 10px;
  width: 95%;
  // aspect-ratio: 16/9;
  border-radius: 5px;
  position: relative;
  background: #f5f6fa;
  margin: 0 auto;
  color: #3d3d3d;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background-image: url(../../assets/bg2.png);
  // background-image: url(../../assets/bg3.jpg);
  background-size: 100% 100%;
  // display: flex;
  // div {
  //   width: 100%;
  //   min-height: 30px;
  //   font-size: 14px;
  //   font-weight: 400;
  //   color: #3d3d3d;
  //   line-height: 30px;
  // }
}
.leftBox {
  font-size: 10px;
  font-weight: 600;
  margin-left: 6.5%;
  width: 30%;
}
.leftDiv {
  margin: 5px 0;
  font-weight: 600;
}
.rightBox {
  width: 58%;
  margin-left: 2%;
  font-size: 9px;
  font-weight: 600;
  position: relative;
  left: -0.5%;
  // margin-left: 5%;
}
.hz {
  position: absolute;
  width: 50%;
  bottom: 0%;
  right: 33%;
  transform: translate(50%,50%)  rotate(-10deg);
}
.ewm {
  width: 32%;
}
.red {
  color: red;
}
/* 定义 CSS 类 */

//  td {
//   height: 5px; /* 默认所有单元格高度 */
// }

</style>


